import React from "react";
import {typeCleanse} from './../js/functions.js'
function Cleanse() {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-6 offset-md-3">
                    <h1 className="heading">
                        TypeCleanse
                    </h1>
                    <p>A lightweight web application built in React to beautify your type.</p>
                    <p className="status"></p>
                    <form className="form-group">
                        <select className="form-select cleanse-selection" aria-label="Default select example">
                            <option value="empty" disabled selected>Select cleanse option</option>
                            <option value="singleSpaces">Single spaces</option>
                            <option value="removeAllSpaces">Remove all spaces</option>
                            <option value="removeLineBreaks">Remove line breaks</option>
                            <option value="createLineBreaks">Line break after each word</option>
                            <option value="removeAllSpecialCharacters">Remove all special characters</option>
                            <option value="lowercase">lowercase</option>
                            <option value="uppercase">UPPERCASE</option>
                            <option value="kebabCase">kebab-case</option>
                            <option value="snakeCase">snake_case</option>
                            <option value="capitalisationCase">Capitalisation Case (All Words)</option>
                            <option value="andToAmpersand">Convert 'and' to '&'</option>
                            <option value="ampersandToAnd">Convert '&' to 'and'</option>
                            <option value="dateSuffixUk">Add '-date' as suffix (UK)</option>
                            <option value="dateSuffixUs">Add '-date' as suffix (US)</option>
                            <option value="wordCount">Count words</option>
                            <option value="characterCount">Count characters</option>
                            <option value="generateLoremIpsum">Generate lorem ipsom</option>
                            <option value="stripHtmlTags">Strip HTML tags</option>
                            {/* <option value="dashesToHyphens">Convert all dashes to hyphens</option>*/}
                                {/*  <option value="dashesToEnDashes">Convert all dashes to en dashes</option>*/}
                            {/*<option value="dashesToEmDashes">Convert all dashes to em dashes</option>*/}
                        </select>
                        <textarea type="text" className="textarea form-control" id="exampleFormControlInput1"
                                  placeholder="Input type here"></textarea>
                        <button type="button" className="btn btn-dark"
                                onClick={() => typeCleanse.cleanseOption()}>Cleanse
                        </button>
                        <button type="button" className="btn btn-dark" onClick={() => typeCleanse.copyText()}>Copy to
                            clipboard
                        </button>
                        <button type="button" className="btn btn-dark" onClick={() => typeCleanse.resetText()}>Reset
                        </button>
                    </form>
                </div>
            </div>
        </div>

    );
}

export default Cleanse;
